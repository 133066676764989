.theme-1 {
  background-color: #1F2430;
  background-image: url('/images/background-1.png');
  h1 {
    color: #FDE254;
  }
  h2 {
    color: #5187c2;
  }
  .text-field {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
  .text-field::placeholder {
    color: #FFFFFF;
  }
  .checkbox-label-text {
    color: #FFFFFF;
  }
  .button {
    color: #1F2430;  
    background-color: #5187c2;
  }
  .checkbox {
    --border: #FFFFFF;
    --tick: #1F2430;
  }
  #theme-icon {
    fill: #5187c2;
  }
  #reload-icon {
    fill: #5187c2;
  }
  #loading-indicator > span {
    border-color: #5187c2;
  }
  .logo {
    fill: #5187c2;
  }
  #error-message {
    color: #ff4d4f;
  }
}

.theme-2 {
  background-color: #352517;
  background-image: url('/images/background-2.png');
  h1 {
    color: #FBBA62;
  }
  h2 {
    color: #F36032;
  }
  .text-field {
    color: #FBBA62;
    border-color: #FBBA62;
  }
  .text-field::placeholder {
    color: #FBBA62;
  }
  .checkbox-label-text {
    color: #FBBA62;
  }
  .button {
    color: #352517;  
    background-color: #FBBA62;
  }
  .checkbox {
    --border: #FBBA62;
    --tick: #1F2430;
  }
  #theme-icon {
    fill: #FBBA62;
  }
  #reload-icon {
    fill: #FBBA62;
  }
  #loading-indicator > span {
    border-color: #FBBA62;
  }
  .logo {
    fill: #F36032;
  }
  #error-message {
    color: #ff4d4f;
  }
}

.theme-3 {
  background-color: #FDFCFD;
  background-image: url('/images/background-3.png');
  h1 {
    color: #2E5C9F;
  }
  h2 {
    color: #231F20;
  }
  .text-field {
    color: #231F20;
    border-color: #231F20;
  }
  .text-field::placeholder {
    color: #231F20;
  }
  .checkbox-label-text {
    color: #231F20;
  }
  .button {
    color: #FDFCFD;  
    background-color: #2E5C9F;
  }
  .checkbox {
    --border: #2E5C9F;
    --tick: #FDFCFD;
  }
  #theme-icon {
    fill: #231F20;
  }
  #reload-icon {
    fill: #231F20;
  }
  #loading-indicator > span {
    border-color: #2E5C9F;
  }
  .logo {
    fill: #231F20;
  }
  #error-message {
    color: #ff4d4f;
  }
}

.theme-4 {
  background-color: #DB0631;
  background-image: url('/images/background-4.png');
  h1 {
    color: #FCDC00;
  }
  h2 {
    color: #FFFFFF;
  }
  .text-field {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
  .text-field::placeholder {
    color: #FFFFFF;
  }
  .checkbox-label-text {
    color: #FFFFFF;
  }
  .button {
    color: #DB0631;  
    background-color: #FFFFFF;
  }
  .checkbox {
    --border: #FFFFFF;
    --tick: #DB0631;
  }
  #theme-icon {
    fill: #FFFFFF;
  }
  #reload-icon {
    fill: #FFFFFF;
  }
  #loading-indicator > span {
    border-color: #FFFFFF;
  }
  .logo {
    fill: #20145f;
  }
  #error-message {
    color: #FCDC00;
  }
}