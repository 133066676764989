@import './themes.less';

#main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 80px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all .3s ease-in-out;
}

.transition-content {
  position: absolute;
  width: 550px;
}

#sign-up-transition {
  z-index: 0;
  transition: opacity .3s ease-in-out;
}

#thank-you-transition {
  z-index: 1;
}

.fade {
  transition: opacity .3s ease-in-out;
}

.no-transition {
  transition: none;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}
.hidden {
  opacity: 0;
  pointer-events: none;
}