h1 {
  font-size: 92px;
  font-weight: 900;
  margin: 0 0 15px 0;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color .3s ease-in-out;
}

h2 {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 20px 0;
  text-transform: uppercase;
  white-space: nowrap;
  transition: color .3s ease-in-out;
}

.form {
  margin-top: 30px;
  width: 100%;
}

#text-fields-container {
  display: flex;
  flex-direction: row;
  width: 500px;
}

#first-name-field {
  margin-right: 15px;
}

#last-name-field {
  margin-left: 15px;
}

.button {
  padding: 12px 40px 17px 40px;
  font-size: 22px;
  line-height: 30px;
  font-family: inherit;
  font-weight: 300;
  border-radius: 2.5rem;
  align-self: flex-start;
  cursor: pointer;
  border: none;
  outline: none;
  transition: all .3s ease-in-out;
}

.button:focus {
  box-shadow: 0 0 1rem rgb(0 0 0 / 30%);
}

.button:disabled {
  cursor: pointer;
  opacity: 0.25;
}

#beer-preferences-title {
  margin-top: 20px;
}

#beer-preference-options {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

#submit-button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 2.4rem 0;
}

#submit-button {
  margin-right: 20px;
}

#submit-button-message-container {
  height: 50px;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

#loading-indicator > span {
  border-bottom-color: transparent !important;
}

#loading-indicator {
  position: absolute;
  z-index: 0;
  transition: opacity .3s ease-in-out;
}

#error-message {
  position: absolute;
  z-index: 1;
  transition: opacity .3s ease-in-out;
  font-size: 20px;
  padding-bottom: 4px;
}