#reload-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  pointer-events: none;
}

#reload-button {
  background-color: transparent;
  width: 70px;
  height: 70px;
  position: relative;
  border: 0;
  outline: none;
  padding: 20px;
  pointer-events: auto;
  cursor: pointer;
  transition: opacity .3s ease-in-out;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
  &:active {
    opacity: 0.4;
  }
}

#reload-icon {
  fill: #FFFFFF;
  width: 100%;
  height: 100%;
};