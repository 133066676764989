body > #root > div {
  width: 100vw;
  height: 100vh;
}

body {
  font-family: "sofia-pro", sans-serif;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  touch-action: none;
}