.text-field {
  margin: 0 0 20px 0;
  padding: .6rem .4rem;
  border: 0;
  border-bottom: 2px solid;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  resize: vertical;
  font-size: 20px;
  line-height: 32px;
  font-family: inherit;
  font-weight: 300;
  width: 100%;
  // overflow: visible;
}