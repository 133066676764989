#thank-you-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo-container{
  height: 80px;
  margin-top: 40px;
}