.checkbox-input {
  margin-right: 10px;
}

.checkbox-label {
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 30px;
}

.checkbox-label-text {
  padding-bottom: 2px;
  box-sizing: border-box;
  margin-left: 10px; 
  font-size: 20px;
}

/* https://codepen.io/aaroniker/pen/PowZbgb */

.checkbox {
  position: relative;
  input,
  svg {
    width: 24px;
    height: 24px;
    display: block;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    outline: none;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    transition: box-shadow .3s;
    box-shadow: inset 0 0 0 var(--s, 2px) var(--b, var(--border));
    &:hover {
      --s: 3px;
    }
  }
  svg {
    pointer-events: none;
    fill: none;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--stroke, var(--border));
    position: absolute;
    top: 4px;
    left: 0;
    width: 24px;
    height: 24px;
    transform: scale(var(--scale, 1)) translateZ(0);
  }
  &.path {
    input {
      &:checked {
        --s: 2px;
        transition-delay: .4s;
        & + svg {
          --a: 16.1 86.12;
          --o: 102.22;
        }
      }
    }
    svg {
      stroke-dasharray: var(--a, 86.12);
      stroke-dashoffset: var(--o, 86.12);
      transition: stroke-dasharray .6s, stroke-dashoffset .6s;
    }
  }
  &.bounce {
    --stroke: var(--tick);
    input {
      &:checked {
        --s: 14px;
        & + svg {
          animation: bounce .4s linear forwards .2s;
        }
      }
    }
    svg {
      --scale: 0;
    }
  }
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(.9);
  }
  100% {
    transform: scale(1);
  }
}